// Dev Icons
import javascripticon from "../../img/skills/javascripticon.svg";
// import nodejsicon from "../../img/skills/nodejsicon.svg";
import reacticon from "../../img/skills/reacticon.svg";
import mongodbicon from "../../img/skills/mongoicon.svg";
import expressicon from "../../img/skills/expressicon.svg";
// import laravel from "../../img/skills/laravel.svg";

// Envirement Icons
import git from "../../img/skills/git.svg";
import github from "../../img/skills/github.svg";
// import postman from "../../img/skills/postman.svg";

// Styles Icons
import bootstrap from "../../img/skills/bootstrap.svg";
import tailwindicon from "../../img/skills/tailwindicon.svg";

//  Icons
// import figmaicon from "../../img/skills/figmaicon.svg";
// import wordpressicon from "../../img/skills/wordpressicon.svg";

// Modules
export const skillsDataWeb = [
  {
    skillsTitle: "WebDevelopment",
    skills: [
      {
        title: "JavaScript",
        hash: "#JavaScript",
        icon: javascripticon,
        color: "#F7DF1E",
        colorDark: "#F7DF1E",
      },
      {
        title: "React",
        hash: "#React",
        icon: reacticon,
        color: "#61DAFB",
        colorDark: "#61DAFB",
      },
      {
        title: "Express",
        hash: "#Express",
        icon: expressicon,
        color: "#aaa",
      },
      {
        title: "MongoDB",
        hash: "#MongoDB",
        icon: mongodbicon,
        color: "#449C45",
        colorDark: "#449C45",
      },
      // {
      //   title: "Node.js",
      //   hash: "#Node.js",
      //   icon: nodejsicon,
      //   color: "#339933",
      //   colorDark: "#339933",
      // },
      // {
      //   title: "laravel",
      //   hash: "#laravel",
      //   icon: laravel,
      //   color: "#ff2d20",
      //   colorDark: "#ff2d20",
      // },
    ],
  },
];
export const environment = [
  {
    skillsTitle: "environment",
    skills: [
      { title: "git", hash: "#git", icon: git, color: "#de4c36" },
      { title: "github", hash: "#github", icon: github, color: "#3e75c3" },
      // { title: "postman", hash: "#postman", icon: postman, color: "#ff6c37" },
    ],
  },
];
export const styles = [
  {
    skillsTitle: "environment",
    skills: [
      {
        title: "Tailwind",
        hash: "#Tailwind",
        icon: tailwindicon,
        color: "#38B2AC",
      },
      {
        title: "Express",
        hash: "#Express",
        icon: bootstrap,
        color: "#563d7c",
      },
    ],
  },
];
